<div fxLayout="row" style="padding: 10px">
    <div fxLayout="column">
        <mat-card style="padding: 10px">
            <mat-card-title style="padding-left: 15px;">
                {{'views.users.search.title' | translate }}
            </mat-card-title>
            <mat-card-content>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <mat-form-field style="padding: 15px;">
                            <mat-label>Id</mat-label>
                            <input matInput placeholder="Id" [(ngModel)]="id">
                        </mat-form-field>
                        <mat-form-field style="padding: 15px;">
                            <mat-label>Email</mat-label>
                            <input matInput [(ngModel)]="email">
                        </mat-form-field>
                    </div>
                    <div fxLayout="column">
                        <mat-form-field appearance="fill" style="padding: 15px;">
                            <mat-label>{{'views.users.search.createdBefore' | translate}}</mat-label>
                            <input (dateInput)="onCreatedBeforeChanged($event)" matInput
                                [matDatepicker]="createdBeforePicker">
                            <mat-datepicker-toggle matIconSuffix [for]="createdBeforePicker"></mat-datepicker-toggle>
                            <mat-datepicker #createdBeforePicker></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" style="padding: 15px;">
                            <mat-label>{{'views.users.search.createdAfter' | translate}}</mat-label>
                            <input (dateInput)="onCreatedAfterChanged($event)" matInput
                                [matDatepicker]="createdAfterPicker">
                            <mat-datepicker-toggle matIconSuffix [for]="createdAfterPicker"></mat-datepicker-toggle>
                            <mat-datepicker #createdAfterPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions style="padding: 15px; padding-left: 35px;">
                <div fxLayout="row">
                    <button mat-raised-button color="primary" [disabled]="loadingUsers" (click)="performSearch()">
                        <mat-icon>search</mat-icon>
                        {{'views.users.search.search' | translate}}
                    </button>
                    <div *ngIf="loadingUsers" style="padding-left: 5px;">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
    <div style="padding-left: 10px" fxLayout="column" *ngIf="!isProd">
        <mat-card style="padding: 10px">
            <mat-card-title style="padding-left: 15px;">
                {{'views.users.test.title' | translate }}
            </mat-card-title>
            <mat-card-content>
                <div fxLayout="row">
                    <div fxLayout="column">
                        <mat-form-field style="padding: 15px;">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" [(ngModel)]="testUserMail">
                        </mat-form-field>

                        <mat-form-field style="padding: 15px;">
                            <mat-label>{{'views.users.test.password' | translate }}</mat-label>
                            <input matInput placeholder="Email" [(ngModel)]="testUserPassword">
                        </mat-form-field>
                    </div>
                    <div fxLayout="column">
                        <mat-slide-toggle [(ngModel)]="testUserAdmin"
                            style="padding-left: 10px;">Admin</mat-slide-toggle>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions style="padding: 15px; padding-left: 35px;">
                <button mat-raised-button color="primary" [disabled]="!isTestUserValid() || isCreatingTestUser"
                    (click)="createTestUser()">
                    <mat-icon>add</mat-icon>
                    {{'views.users.test.create' | translate }}
                </button>
                <div *ngIf="isCreatingTestUser" style="padding-left: 5px;">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>
</div>


<div style="padding: 10px;" *ngIf="!loadingUsers || isCreatingTestUser">
    <mat-card>
        <mat-card-content>
            <table mat-table [dataSource]="userDataSource">
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> Id </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> {{'views.users.search.table.phone' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef> {{'views.users.search.table.createdAt' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{(element.createdAt * 1000) | date:'dd-MM-yy hh:mm:ss'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                    <th mat-header-cell *matHeaderCellDef> {{'views.users.search.table.updatedAt' | translate}}</th>
                    <td mat-cell *matCellDef="let element"> {{(element.updatedAt * 1000) | date:'dd-MM-yy hh:mm:ss'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="isAdmin">
                    <th mat-header-cell *matHeaderCellDef> Admin </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="isAdmin(element)">
                            <mat-icon style="color: green">done</mat-icon>
                        </div>
                        <div *ngIf="!isAdmin(element)">
                            <mat-icon style="color: red">close</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['id', 'email', 'phone', 'createdAt', 'updatedAt', 'isAdmin']">
                </tr>
                <tr mat-row
                    *matRowDef="let row; columns: ['id', 'email', 'phone', 'createdAt', 'updatedAt', 'isAdmin'];">
                </tr>
            </table>
            <mat-paginator [pageSizeOptions]="[10, 15, 20]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPageChange($event)" [length]="userCount"
                showFirstLastButtons></mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
