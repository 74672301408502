<mat-card>
    <mat-card-actions>
        <mat-form-field style="padding: 15px;">
            <mat-label>
                <mat-icon style="padding-right: 5px;">search</mat-icon>{{'views.storage.nameCriteria' |
                translate}}</mat-label>
            <input matInput [(ngModel)]="searchCriteria" (ngModelChange)="performSearch()">
        </mat-form-field>
        <div fxFlex></div>
        <input type="file" class="file-input" multiple="multiple" (change)="onFileSelected($event)" #fileUpload
            [hidden]="true">
        <div *ngIf="isLoaded()">
            <mat-icon class="green-check">check_circle</mat-icon>
        </div>
        <div *ngIf="isLoading()">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <button mat-raised-button (click)="fileUpload.click()" color="primary">{{'views.storage.uploadFile' |
            translate}}</button>
    </mat-card-actions>
</mat-card>
<div fxLayout="row wrap">
    <div style="padding: 10px;" *ngFor="let file of filesToDisplay">
        <mat-card>
            <mat-card-title>
                {{file.supabaseFile.name}}
            </mat-card-title>
            <img width="300" height="300" mat-card-image ngSrc="{{file.url}}" alt="Italian Trulli">
            <mat-card-actions>
                <button style="padding: 10px;" mat-icon-button color="primary" (click)="openSourceImage(file)">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="deleteImage(file)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
