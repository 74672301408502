import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './common/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    var userLang = StorageService.getOrDefault(StorageService.SELECTED_LANG_KEY, 'pl');
    this.translate.setDefaultLang(userLang);
    this.translate.use(userLang);
  }
}
