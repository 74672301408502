<mat-card>
  <mat-card-content>
    <div fxLayout="column">
      <mat-form-field appearance="fill">
        <mat-label>{{'views.templates.name' | translate}}</mat-label>
        <input type="text"
               matInput
               [(ngModel)]="name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{'views.templates.description' | translate}}</mat-label>
        <input matInput [(ngModel)]="description">
      </mat-form-field>
      <mat-checkbox matInput [(ngModel)]="requireReps">{{'views.templates.requireReps' | translate}}</mat-checkbox>
      <mat-checkbox matInput [(ngModel)]="requireLength">{{'views.templates.requireLength' | translate}}</mat-checkbox>
      <mat-checkbox matInput [(ngModel)]="requireIntensity">{{'views.templates.requireIntensity' | translate}}</mat-checkbox>
      <mat-checkbox matInput [(ngModel)]="requireWeight">{{'views.templates.requireWeight' | translate}}</mat-checkbox>

      <mat-form-field>
        <mat-label>{{'views.templates.muscleGroups' | translate}}</mat-label>
        <mat-chip-grid #muscleGroupChipGrid>
          <mat-chip-row *ngFor="let muscleGroup of muscleGroups" (removed)="removeMuscleGroup(muscleGroup)">
            {{muscleGroup}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="{{'views.templates.addMuscleGroup' | translate}}" #muscleGroupInput
               [matChipInputFor]="muscleGroupChipGrid" [matAutocomplete]="autoMuscleGroup" [formControl]="muscleController"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="addMuscleGroup($event)"/>
        <mat-autocomplete #autoMuscleGroup="matAutocomplete" (optionSelected)="selectedMuscleGroup($event)">
          <mat-option *ngFor="let muscleGroup of filteredMuscleGroups | async" [value]="muscleGroup">
            {{muscleGroup}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'views.templates.equipments' | translate}}</mat-label>
        <mat-chip-grid #equipmentChipGrid>
          <mat-chip-row *ngFor="let equipment of equipments" (removed)="removeEquipment(equipment)">
            {{equipment}}
            <button matChipRemove>
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="{{'views.templates.addEquipment' | translate}}" #equipmentInput
               [matChipInputFor]="equipmentChipGrid" [matAutocomplete]="autoEquipment" [formControl]="equipmentController"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               (matChipInputTokenEnd)="addEquipment($event)"/>
        <mat-autocomplete #autoEquipment="matAutocomplete" (optionSelected)="selectedEquipment($event)">
          <mat-option *ngFor="let equipment of filteredEquipments | async" [value]="equipment">
            {{equipment}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button color="primary" [disabled]="!canSave()" (click)="save()">{{'views.translation.save' | translate}}</button>
  </mat-card-actions>
</mat-card>
