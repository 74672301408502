import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../common/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ga-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.sass']
})
export class NavComponent {
  @ViewChild(MatSidenav, { static: true })
  sideNav?: MatSidenav;

  selectedLanguage: string;
  fullSideNavView: boolean = true;

  constructor(
    private authService: AuthService, 
    private changeDetectorRef: ChangeDetectorRef, 
    private translateService: TranslateService,
    private router: Router) {
    this.selectedLanguage = StorageService.getOrDefault(StorageService.SELECTED_LANG_KEY, 'pl')
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  isSelected(path: string): boolean {
    return this.router.url == path;
  }

  choseLanguage(languageCode: string): void {
    this.selectedLanguage = languageCode;
    this.translateService.use(this.selectedLanguage);
    StorageService.add(StorageService.SELECTED_LANG_KEY, languageCode);
    this.changeDetectorRef.detectChanges();
  }

  logout(): void {
    this.authService.logout();
  }

  isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  getCurrentUserName(): string {
    return this.authService.getUserMail();
  }

  toggle(): void {
    this.fullSideNavView = !this.fullSideNavView;
  }
}
