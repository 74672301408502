<mat-card fxFlexAlign="stretch" style="min-width: 90vw; min-height: 90vh; padding: 10px;">
    <mat-card-title>
        {{'views.translation.title' | translate}}
    </mat-card-title>
    <mat-card-actions style="display: inline-block;">
        <mat-form-field style="padding: 15px;">
            <mat-label><mat-icon style="padding-right: 5px;">search</mat-icon>{{'views.translation.key' |
                translate}}</mat-label>
            <input matInput [(ngModel)]="keyCriteria" (ngModelChange)="performSearch()">
        </mat-form-field>
        <mat-form-field style="padding: 15px;">
            <mat-label><mat-icon style="padding-right: 5px;">search</mat-icon>{{'views.translation.value' |
                translate}}</mat-label>
            <input matInput [(ngModel)]="valueCriteria" (ngModelChange)="performSearch()">
        </mat-form-field>
        <div fxFlex></div>
            <button mat-raised-button color="primary" [disabled]="loadingTranslations"
                (click)="openNewTranslationModal()">
                {{'views.translation.addNewTranslation' | translate}}
            </button>
            <span style="padding-left: 10px;"></span>
            <button mat-raised-button color="primary"
                [disabled]="loadingTranslations || countTranslationsToSaveInTable() == 0"
                (click)="saveTranslationsInTable()">
                {{'views.translation.save' | translate}} ({{countTranslationsToSaveInTable()}})
            </button>
    </mat-card-actions>
    <mat-card-content>
        <div *ngIf="loadingTranslations">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
        <mat-card style="padding-top: 15px;">
            <mat-card-content>
                <table mat-table [dataSource]="translationDataSource">
                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef> {{'views.translation.key' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.key}} </td>
                    </ng-container>

                    <ng-container *ngFor="let langCode of langCodes" matColumnDef="{{langCode}}">
                        <th mat-header-cell *matHeaderCellDef> {{langCode | uppercase}} </th>
                        <td mat-cell *matCellDef="let element">
                            <mat-form-field style="width: 100%;">
                                <input type="text" matInput [ngModel]="getTranslationValue(element, langCode)"
                                    (ngModelChange)="setNewTranslationValue($event, langCode, element.key)">
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="getColumnNames()"></tr>
                    <tr mat-row *matRowDef="let row; columns: getColumnNames();"></tr>
                </table>
                <mat-paginator #paginator [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons></mat-paginator>
            </mat-card-content>
        </mat-card>
    </mat-card-content>
</mat-card>