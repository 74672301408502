import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/app/env/environment';

@Component({
  selector: 'ga-config-new-translation',
  templateUrl: './new-translation.component.html',
  styleUrls: ['./new-translation.component.sass']
})
export class NewTranslationComponent {

  private readonly BASE_URL = environment.serverUrl;

  langCode: string = "";
  key: string = "";
  value: string = "";

  availableLanguages: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<NewTranslationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private httpClient: HttpClient) {
    this.availableLanguages = data.langCodes;
  }

  save() {
    if (this.key && this.value && this.langCode) {
      this.httpClient.post<any>(`${this.BASE_URL}/admin/translation/${this.langCode}`,
      {
        key: this.key,
        value: this.value
      }).subscribe(_ => this.dialogRef.close(true));
    }
  }
}
