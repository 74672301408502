import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from "./login/login.component";
import { MaterialModule } from "./material.module";
import { NavComponent } from './navigation/nav.component';
import { ConfigComponent } from './views/config/config.component';
import { UsersComponent } from './views/users/users.component';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomHttpInterceptor } from './auth/http-interceptor.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslationComponent } from './views/config/translation/translation.component';
import { NewTranslationComponent } from './views/config/translation/new-translation/new-translation.component';
import { StorageComponent } from './views/storage/storage.component';
import { ExerciseTemplateComponent } from './views/exercise-template/exercise-template.component';
import {NgOptimizedImage} from "@angular/common";
import {NewTemplateComponent} from "./views/exercise-template/new-template/new-template.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    ConfigComponent,
    TranslationComponent,
    NewTranslationComponent,
    UsersComponent,
    StorageComponent,
    ExerciseTemplateComponent,
    NewTemplateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgOptimizedImage,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
  entryComponents: [NewTranslationComponent, NewTemplateComponent]
})
export class AppModule {
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
