export class TranslationRow {
    key: string;
    valuePerLangCode: Map<string, string>;
    newValuePerLangCode: Map<string, string>;

    constructor(key: string, value: string, langCode: string) {
        this.key = key;
        this.valuePerLangCode = new Map();
        this.newValuePerLangCode = new Map();
        this.addTranslation(value, langCode);
    }

    addTranslation(value: string, langCode: string) {
        this.valuePerLangCode.set(langCode, value);
    }

    addNewTranslation(value: string, langCode: string) {
        this.newValuePerLangCode.set(langCode, value);
    }
}

export interface TranslationDocument {
    translationsPerLanguageList: LangTranslationDocument[];
}

export interface LangTranslationDocument {
    languageCode: string;
    translations: TranslationEntryDocument[];
}

export interface TranslationEntryDocument {
    key: string;
    value: string;
}