import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthResponse, createClient, SupabaseClient } from "@supabase/supabase-js";
import { NotificationsService } from "angular2-notifications";
import { EMPTY, from, map, Observable, of, tap } from "rxjs";
import { environment } from "../env/environment";
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtToken } from "./auth.model";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from "../common/storage.service";

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private readonly SUPABASE_URL = environment.supabaseUrl;
    private readonly SUPABASE_KEY = environment.supabaseKey;
    private readonly ADMIN_ROLE_KEY = "ROLE_ADMIN";

    private inMemoryJwt: JwtToken | null = null;
    private rawJwt: string | null = null;

    private readonly client: SupabaseClient
    private readonly jwtService: JwtHelperService

    constructor(private router: Router, private notificationService: NotificationsService, private translationService: TranslateService) {
        this.client = createClient(this.SUPABASE_URL, this.SUPABASE_KEY);
        this.jwtService = new JwtHelperService();
    }

    login(username: string, password: string) {
        from(
            this.client.auth.signInWithPassword({
                email: username,
                password: password
            })
        ).subscribe({
            next: (response) => {
                if (!response.error) {
                    this.handleAuthResponse(response);
                    this.router.navigateByUrl('/')
                } else {
                    this.notificationService.error(
                        this.translationService.instant('auth.failedLogin.title'),
                        this.translationService.instant('auth.failedLogin.content'),
                    );
                }
            },
            error: () => console.log("error")
        });
    }

    logout() {
        StorageService.removeAll([
            StorageService.JWT_TOKEN_KEY,
            StorageService.REFRESH_TOKEN_KEY,
            StorageService.USER_MAIL,
        ]);
        this.rawJwt = null;
        this.inMemoryJwt = null;
        this.router.navigateByUrl("/login")
    }

    isAuthenticated(): boolean {
        return this.getJwt() != null;
    }

    isAdmin(): boolean {
        var jwt = this.getJwt()
        if (jwt == null) {
            return false;
        }

        if (!jwt.app_metadata.roles) {
            return false;
        }
        return jwt.app_metadata.roles.indexOf(this.ADMIN_ROLE_KEY) > -1;
    }

    getUserMail(): string {
        return StorageService.getOrDefaultFunction(
            StorageService.USER_MAIL,
            () => this.translationService.instant('auth.userEmailUnknown')
        )
    }

    getOrRefreshRawJwt(): Observable<string> {
        if (this.rawJwt != null) {
            return of(this.rawJwt);
        }

        var localStorageJwt = StorageService.get(StorageService.JWT_TOKEN_KEY);
        if (localStorageJwt != null) {
            this.rawJwt = localStorageJwt;
        }

        var isExpired = this.jwtService.isTokenExpired(this.rawJwt, 60 * 60 * 2);
        if (isExpired || this.rawJwt == null) {
            var refreshToken = StorageService.get(StorageService.REFRESH_TOKEN_KEY)
            if (refreshToken == null) {
                this.logout();
                return EMPTY;
            }

            return from(this.client.auth.refreshSession({ refresh_token: refreshToken }))
                .pipe(
                    tap(response => this.handleAuthResponse(response)),
                    map(() => this.rawJwt!!)
                )
        } else {
            return of(this.rawJwt);
        }
    }

    getJwt(): JwtToken | null {
        if (this.inMemoryJwt != null) {
            return this.inMemoryJwt;
        }

        var rawJwt = this.getRawJwt();
        if (rawJwt == null) {
            return null;
        }

        var decodedToken = this.jwtService.decodeToken<JwtToken>(rawJwt);
        if (decodedToken == null) {
            return null;
        }

        this.inMemoryJwt = decodedToken;
        return this.inMemoryJwt;
    }

    private getRawJwt(): string | null {
        if (this.rawJwt != null) {
            return this.rawJwt;
        }

        return StorageService.get(StorageService.JWT_TOKEN_KEY);
    }

    private handleAuthResponse(response: AuthResponse) {
        if (response.error == null) {
            var rawJwtToken = response.data.session?.access_token!!;
            this.rawJwt = rawJwtToken;
            StorageService.add(StorageService.JWT_TOKEN_KEY, rawJwtToken);
            StorageService.add(StorageService.REFRESH_TOKEN_KEY, response.data.session?.refresh_token!!);
            StorageService.add(StorageService.USER_MAIL, response.data.user?.email!!);
            this.inMemoryJwt = this.jwtService.decodeToken<JwtToken>(rawJwtToken)!!;
        } else {
            this.notificationService.error(
                this.translationService.instant('auth.failedLogin.title'),
                this.translationService.instant('auth.failedLogin.content'),
            );
            this.logout();
        }
    }
}