import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, mergeMap, tap } from "rxjs";

import { Observable } from "rxjs/internal/Observable";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.includes('/assets/i18n/')) {
            return next.handle(req);
        }
        return this.addCredentials(req)
            .pipe(
                mergeMap(req => next.handle(req))
            );
    }

    private addCredentials(request: HttpRequest<any>): Observable<HttpRequest<any>> {
        return this.authService.getOrRefreshRawJwt()
            .pipe(
                map(token => request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${token}`)
                }))
            );
    }

}