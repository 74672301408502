import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/app/env/environment';
import { TranslationDocument, TranslationRow } from './translation.model';
import { MatPaginator } from '@angular/material/paginator';
import { Observable, forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NewTranslationComponent } from './new-translation/new-translation.component';

@Component({
  selector: 'ga-config-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.sass']
})
export class TranslationComponent implements AfterViewInit {

  private readonly BASE_URL = environment.serverUrl;

  translations: TranslationDocument | null = null;

  loadingTranslations = false;

  translationDataSource = new MatTableDataSource<TranslationRow>();
  langCodes: string[] = [];

  keyCriteria: string | null = null;
  valueCriteria: string | null = null;

  @ViewChild(MatPaginator, { static: false })
  paginator?: MatPaginator;

  constructor(private httpClient: HttpClient, private dialog: MatDialog) {
    this.performApiSearch();
  }

  ngAfterViewInit(): void {
    this.translationDataSource.paginator = this.paginator!!;
  }

  performSearch() {
    if (this.translations!!) {
      this.handleTranslationSearchResult(this.translations);
    }
  }

  performApiSearch() {
    this.loadingTranslations = true;
    this.httpClient.get<string[]>(`${this.BASE_URL}/translation/languages`)
      .subscribe(allLanguages => {
        this.langCodes = allLanguages;

        this.httpClient.get<TranslationDocument>(`${this.BASE_URL}/translation`)
          .subscribe(translations => this.handleTranslationSearchResult(translations));
      })
  }

  getColumnNames(): string[] {
    const columns = ['key'];
    this.langCodes.forEach(e => columns.push(e));
    return columns;
  }

  getTranslationValue(row: TranslationRow, langCode: string): string {
    const translation = row.valuePerLangCode.get(langCode);
    if (translation === null || translation === undefined) {
      return "";
    }
    return translation;
  }

  setNewTranslationValue(newValue: string, langCode: string, key: string) {
    this.translationDataSource.data.filter(e => e.key == key)[0].addNewTranslation(newValue, langCode);
  }

  openNewTranslationModal() {
    this.dialog.open(NewTranslationComponent, {
      data: { langCodes: this.langCodes }
    }).afterClosed().subscribe(saveSuccessful => {
      if (saveSuccessful) {
        this.performApiSearch();
      }
    });
  }

  countTranslationsToSaveInTable(): number {
    var count = 0;
    this.translationDataSource.data
      .forEach(e => {
        e.newValuePerLangCode.forEach((value, langCode) => {
          count++;
        });
      });
    return count;
  }

  saveTranslationsInTable() {
    const observables: Observable<any>[] = [];
    this.translationDataSource.data
      .forEach(e => {
        e.newValuePerLangCode.forEach((value, langCode) => {
          const o = this.httpClient.post(`${this.BASE_URL}/admin/translation/${langCode}`, {
            key: e.key,
            value: value
          });
          observables.push(o);
        });
      });
    forkJoin(observables).subscribe(_ => this.performApiSearch());
  }

  private handleTranslationSearchResult(translations: TranslationDocument) {
    const translationRows: TranslationRow[] = [];
    this.translations = translations;
    if (this.translations.translationsPerLanguageList) {
      this.translations.translationsPerLanguageList.forEach(element => {
        element.translations
          .forEach(t => {
            if (translationRows.filter(e => e.key === t.key).length === 1) {
              translationRows.filter(e => e.key === t.key)[0].addTranslation(t.value, element.languageCode);
            } else {
              translationRows.push(new TranslationRow(t.key, t.value, element.languageCode));
            }
          });
      });
    }

    this.translationDataSource.data = translationRows
      .filter(t => {
        if (this.keyCriteria) {
          return t.key.includes(this.keyCriteria);
        } else {
          return true;
        }
      })
      .filter(t => {
        if (this.valueCriteria) {
          return [...t.valuePerLangCode].filter(([k, v]) => v.includes(this.valueCriteria!!)).length > 0
        } else {
          return true;
        }
      });
    this.loadingTranslations = false;
  }
}
