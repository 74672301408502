export class StorageService {

    static readonly SELECTED_LANG_KEY: string = "selected_lang"
    static readonly JWT_TOKEN_KEY = "jwt_token";
    static readonly REFRESH_TOKEN_KEY = "refresh_token";
    static readonly USER_MAIL = "user_mail";

    public static add(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    public static get(key: string): string | null {
        return localStorage.getItem(key);
    }

    public static removeAll(keys: string[]) {
        keys.forEach(k => localStorage.removeItem(k));
    }

    public static remove(key: string) {
        localStorage.removeItem(key);
    }
    
    public static getOrDefault(key: string, defaultValue: string): string {
        return StorageService.getOrDefaultFunction(key, () => defaultValue);
    }

    public static getOrDefaultFunction(key: string, defaultValueFunction: () => string): string {
        var localStorageValue = localStorage.getItem(key);
        if (localStorageValue == null) {
            return defaultValueFunction();
        } else {
            return localStorageValue;
        }
    }
}