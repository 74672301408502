import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AuthGuardService } from './auth/auth.guard';
import { NavComponent } from './navigation/nav.component';
import { ConfigComponent } from './views/config/config.component';
import { UsersComponent } from './views/users/users.component';
import {LoginComponent} from "./login/login.component";
import { AdminAuthGuardService } from './auth/admin-auth.guard';
import { StorageComponent } from './views/storage/storage.component';
import { ExerciseTemplateComponent } from './views/exercise-template/exercise-template.component';

const routes: Routes = [
  {
    path: 'login',
    title: 'GymApka BO',
    component: LoginComponent
  },
  {
    path: '',
    title: 'GymApka BO',
    component: NavComponent,
    children: [
      {
        path: 'admin/config',
        component: ConfigComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
      },
      {
        path: 'admin/users',
        component: UsersComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
      },
      {
        path: 'admin/storage',
        component: StorageComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
      },
      {
        path: 'admin/exercise-template',
        component: ExerciseTemplateComponent,
        canActivate: [AuthGuardService, AdminAuthGuardService]
      }
    ],
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule {
}
