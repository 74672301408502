import {HttpClient} from '@angular/common/http';
import {Component} from '@angular/core';
import {
  ExerciseTemplateDocument,
  FindEquipmentsDocument,
  FindExerciseTemplateDocument, FindMuscleGroupsDocument
} from './exercise-template.model';
import {MatDialog} from "@angular/material/dialog";
import {NewTemplateComponent} from "./new-template/new-template.component";
import {environment} from "../../env/environment";

@Component({
  selector: 'ga-exercise-template',
  templateUrl: './exercise-template.component.html',
  styleUrls: ['./exercise-template.component.sass']
})
export class ExerciseTemplateComponent {

  private readonly BASE_URL = environment.serverUrl;
  private allEquipments: string[] = [];
  private allMuscleGroups: string[] = [];

  templates: ExerciseTemplateDocument[] = [];

  constructor(private httpClient: HttpClient, private dialog: MatDialog) {
    this.performApiSearch();
    this.httpClient.get<FindEquipmentsDocument>(`${this.BASE_URL}/exercise/template/system/equipments`)
      .subscribe(response =>
        this.allEquipments = [...new Set(response.equipments?.map(eq => eq.name))]
      );
    this.httpClient.get<FindMuscleGroupsDocument>(`${this.BASE_URL}/exercise/template/system/muscle-groups`)
      .subscribe(response =>
        this.allMuscleGroups = [...new Set(response.muscleGroups?.map(mg => mg.name))]
      );

  }

  orZero(value: number | undefined | null): number {
    if (!!value) {
      return value;
    } else {
      return 0;
    }
  }

  openNewTranslationModal() {
    this.dialog.open(NewTemplateComponent, {
      width: '33vw',
      data: {
        muscleGroups: this.allMuscleGroups,
        equipments: this.allEquipments
      }
    }).afterClosed().subscribe(saveSuccessful => {
      if (saveSuccessful) {
        this.performApiSearch();
      }
    });
  }

  deleteTemplate(templateId: number) {
    this.httpClient.delete(`${this.BASE_URL}/exercise/template/system/${templateId}`)
      .subscribe(_ => this.performApiSearch());
  }

  private performApiSearch() {
    this.httpClient.get<FindExerciseTemplateDocument>(`${this.BASE_URL}/exercise/template/system`)
      .subscribe(result => this.templates = result.exerciseTemplates);
  }
}
