<mat-toolbar class="ga-toolbar">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1>Gymapka</h1>
    <span class="ga-menu-spacer"></span>
    <button mat-button [matMenuTriggerFor]="languageMenu">
      <img src="assets/language-icons/{{selectedLanguage}}.png" />
    </button>
    <mat-menu #languageMenu>
      <button mat-menu-item (click)="choseLanguage('pl')">
        <img src="assets/language-icons/pl.png" />
        PL
      </button>
      <button mat-menu-item (click)="choseLanguage('en')">
        <img src="assets/language-icons/en.png" />
        EN
      </button>
    </mat-menu>
    <button mat-button [matMenuTriggerFor]="userMenu">
      <mat-icon>
        account_circle
      </mat-icon>
      <span style="color: white; padding-left: 5px">{{getCurrentUserName()}}</span>
    </button>
    <mat-menu #userMenu>
      <button mat-menu-item (click)="logout()">
        {{ 'nav.logout' | translate }}
        <mat-icon>power_settings_new</mat-icon>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container autosize>
  <mat-sidenav class="ga-sidenav" [mode]="'side'" [opened]="true">
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/admin/users'" *ngIf="isAdmin()">
        <span [ngClass]="isSelected('/admin/users') ? 'ga-sidenav-element-selected' : 'ga-sidenav-element'">
          <mat-icon>manage_accounts</mat-icon>
          <span *ngIf="fullSideNavView">{{ 'nav.users' | translate }}</span>
        </span>
      </a>
      <a mat-list-item [routerLink]="'/admin/config'" *ngIf="isAdmin()">
        <span [ngClass]="isSelected('/admin/config') ? 'ga-sidenav-element-selected' : 'ga-sidenav-element'">
          <mat-icon>translate</mat-icon>
          <span *ngIf="fullSideNavView">{{ 'nav.translation' | translate }}</span>
        </span>
      </a>
      <a mat-list-item [routerLink]="'/admin/storage'" *ngIf="isAdmin()">
        <span [ngClass]="isSelected('/admin/storage') ? 'ga-sidenav-element-selected' : 'ga-sidenav-element'">
          <mat-icon>photo</mat-icon>
          <span *ngIf="fullSideNavView">{{ 'nav.storage' | translate }}</span>
        </span>
      </a>
      <a mat-list-item [routerLink]="'/admin/exercise-template'" *ngIf="isAdmin()">
        <span [ngClass]="isSelected('/admin/exercise-template') ? 'ga-sidenav-element-selected' : 'ga-sidenav-element'">
          <mat-icon>fitness_center</mat-icon>
          <span *ngIf="fullSideNavView">{{ 'nav.exercise-template' | translate }}</span>
        </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 95vh">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>