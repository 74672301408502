import { Component } from '@angular/core';
import { SupabaseClient, createClient } from '@supabase/supabase-js';
import { FileObject } from '@supabase/storage-js';
import { environment } from 'src/app/env/environment';
import { Observable, forkJoin, from } from 'rxjs';

@Component({
  selector: 'ga-storage',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.sass']
})
export class StorageComponent {

    private readonly GLOBAL_BUCKET_NAME = 'global';

    private readonly SUPABASE_URL = environment.supabaseUrl;
    private readonly SUPABASE_KEY = environment.supabaseKey;

    private readonly client: SupabaseClient;

    files: FileWrapper[] = [];
    filesToDisplay: FileWrapper[] = [];

    searchCriteria = '';
    fileUploadStatus = FileUploadStatus.NONE;

    constructor() {
        this.client = createClient(this.SUPABASE_URL, this.SUPABASE_KEY);
        this.reloadFiles();
    }

    openSourceImage(file: FileWrapper) {
        window.open(file.url, '_blank');
    }

    deleteImage(file: FileWrapper) {
        this.client.storage.from(this.GLOBAL_BUCKET_NAME).remove([file.supabaseFile.name]).then(r => {
            if (!r.error) {
                this.reloadFiles();
            }
        })
    }

    performSearch() {
        this.filesToDisplay = this.files.filter(f =>  {
            if (!!this.searchCriteria) {
                return f.supabaseFile.name.toLowerCase().includes(this.searchCriteria.toLowerCase());
            } else {
                return true;
            }
        })
    }

    onFileSelected(e: any) {
        this.fileUploadStatus = FileUploadStatus.LOADING;
        const observables: Observable<any>[] = [];
        const fileList: FileList = e.target.files;
        Array.from(fileList).forEach((file: File) => {
            var promise = this.client.storage.from(this.GLOBAL_BUCKET_NAME).upload(file.name, file);
            observables.push(from(promise));
        });
        forkJoin(observables).subscribe(_ => {
            this.fileUploadStatus = FileUploadStatus.LOADED;
            this.reloadFiles();
            setTimeout(() => {
                this.fileUploadStatus = FileUploadStatus.NONE;
            }, 1000 * 8);
        });
    }

    isLoaded(): boolean {
        return FileUploadStatus[this.fileUploadStatus] === FileUploadStatus[FileUploadStatus.LOADED];
    }

    isLoading(): boolean {
        return FileUploadStatus[this.fileUploadStatus] === FileUploadStatus[FileUploadStatus.LOADING];
    }

    private reloadFiles() {
        this.client.storage.from(this.GLOBAL_BUCKET_NAME).list().then(r => {
            const foundFiles = r.data?.filter(maybeFile => !!maybeFile.id).map(file => {
                const url = this.client.storage.from(this.GLOBAL_BUCKET_NAME).getPublicUrl(file.name).data.publicUrl;
                return new FileWrapper(file, url);
            });

            if (foundFiles !== undefined) {
                this.files = foundFiles;
                this.performSearch();
            }
        });
    }
}

class FileWrapper {
    constructor(public supabaseFile: FileObject, public url: string) {}
}

enum FileUploadStatus {
    NONE,
    LOADING,
    LOADED
}
