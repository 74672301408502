<mat-card>
    <mat-card-content>
        <div fxLayout="column">
            <mat-form-field appearance="fill">
                <mat-label>{{'views.translation.langCode' | translate}}</mat-label>
                <input type="text"
                       matInput
                       [matAutocomplete]="auto"
                       [(ngModel)]="langCode">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let langCode of availableLanguages" [value]="langCode">
                    {{langCode | uppercase }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'views.translation.key' | translate}}</mat-label>
                <input matInput [(ngModel)]="key">
            </mat-form-field>
            <mat-form-field  appearance="fill">
                <mat-label>{{'views.translation.value' | translate}}</mat-label>
                <input matInput [(ngModel)]="value">
            </mat-form-field>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="save()">{{'views.translation.save' | translate}}</button>
    </mat-card-actions>
</mat-card>