import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from 'src/app/env/environment';
import {FormControl} from "@angular/forms";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {map, Observable, startWith} from "rxjs";
import {FindEquipmentsDocument, FindMuscleGroupsDocument} from "../exercise-template.model";

@Component({
  selector: 'ga-new-template',
  templateUrl: './new-template.component.html',
  styleUrls: ['./new-template.component.sass']
})
export class NewTemplateComponent {

  private readonly BASE_URL = environment.serverUrl;

  separatorKeysCodes: number[] = [ENTER, COMMA];

  name = '';
  description = '';
  requireLength = false;
  requireIntensity = false;
  requireReps = false;
  requireWeight = false;


  muscleGroups: string[] = [];
  filteredMuscleGroups: Observable<string[]>;
  allMuscleGroups = [];

  @ViewChild('muscleGroupInput') muscleGroupInput?: ElementRef<HTMLInputElement>;
  muscleController = new FormControl('');

  equipments: string[] = [];
  filteredEquipments: Observable<string[]>;
  allEquipments = [];

  @ViewChild('equipmentInput') equipmentInput?: ElementRef<HTMLInputElement>;
  equipmentController = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<NewTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private httpClient: HttpClient) {
    this.allMuscleGroups = data.muscleGroups;
    this.allEquipments = data.equipments;
    this.filteredMuscleGroups = this.muscleController.valueChanges.pipe(
      startWith(null),
      map((v: string | null) => (v ? this.filter(this.allMuscleGroups, v) : this.allMuscleGroups.slice())),
    );
    this.filteredEquipments = this.equipmentController.valueChanges.pipe(
      startWith(null),
      map((v: string | null) => (v ? this.filter(this.allEquipments, v) : this.allEquipments.slice())),
    );
  }

  removeMuscleGroup(groupName: string) {
    this.remove(this.muscleGroups, groupName);
  }

  addMuscleGroup(event: MatChipInputEvent): void {
    this.add(this.muscleGroups, this.muscleController, event);
  }

  selectedMuscleGroup(event: MatAutocompleteSelectedEvent): void {
    console.log('selectedMuscleGroup')
    this.muscleGroups.push(event.option.viewValue);
    this.muscleGroupInput!!.nativeElement.value = '';
    this.muscleController.setValue(null);
  }

  removeEquipment(eq: string) {
    this.remove(this.equipments, eq);
  }

  addEquipment(event: MatChipInputEvent): void {
    this.add(this.equipments, this.equipmentController, event);
  }

  selectedEquipment(event: MatAutocompleteSelectedEvent): void {
    console.log('selectedEquipment')
    this.equipments.push(event.option.viewValue);
    this.equipmentInput!!.nativeElement.value = '';
    this.equipmentController.setValue(null);
  }

  save() {
    if (this.canSave()) {
      this.httpClient.post(`${this.BASE_URL}/exercise/template/system`,
        {
          name: this.name,
          description: this.description,
          requireReps: this.requireReps,
          requireLength: this.requireLength,
          requireWeight: this.requireWeight,
          requireIntensity: this.requireIntensity,
          muscleGroups: this.muscleGroups,
          equipments: this.equipments
        }
      ).subscribe(_ => this.dialogRef.close(true));
    }
  }

  canSave(): boolean {
    return !!this.name && !!this.description &&
      (this.requireWeight || this.requireIntensity || this.requireReps || this.requireLength);
  }

  private remove(array: string[], groupName: string) {
    const index = array.indexOf(groupName);

    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  private add(currentValues: string[], controller: FormControl, event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      currentValues.push(value);
    }

    event.chipInput!.clear();

    controller.setValue(null);
  }

  private filter(array: string[], value: string): string[] {
    const filterValue = value.toLowerCase();

    return array.filter(v => v.toLowerCase().includes(filterValue));
  }
}
