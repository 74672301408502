import { Component, HostListener } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'ga-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {

  usernameInput: string = '';
  passwordInput: string = '';

  constructor(
    private authService: AuthService
  ) {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.login();
    }
  }

  login() {
    if (this.isInputValid()) {
      this.authService.login(this.usernameInput, this.passwordInput);
    }
  }

  isInputValid(): boolean {
    return this.usernameInput.length !== 0 && this.passwordInput.length !== 0;
  }
}
