<div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 300px;">
  <mat-card style="padding-left: 30px; padding-right: 30px;">
    <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 30px;">
      <mat-card-title>GA BackOffice</mat-card-title>
    </div>
    <mat-card-content style="padding-top: 30px;">
      <div fxLayout="row">
        <mat-form-field>
          <input type="text" matInput [(ngModel)]="usernameInput" placeholder="Email">
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field>
          <input type="password" matInput [(ngModel)]="passwordInput" placeholder="{{'auth.login.password' | translate}}'">
        </mat-form-field>
      </div>

      <div fxLayoutAlign="center">
        <button (click)="login()" [disabled]="!isInputValid()" mat-raised-button color="primary">Login</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>