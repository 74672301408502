<mat-card>
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="openNewTranslationModal()">
      {{'views.templates.create' | translate}}
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-actions>
</mat-card>
<div fxLayout="row wrap">
  <div style="padding: 10px; min-width: 300px;" *ngFor="let template of templates">
    <mat-card>
      <mat-card-title style="padding: 10px">
        <span title="{{template.name}}">{{ template.name }}</span>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="column">
          <span style="padding-bottom: 10px" title="{{ template.description }}"><i>{{ template.description }}</i></span>
          <span fxLayout="row">{{'views.templates.requireReps' | translate }}
            <div fxFlex></div>
            <div *ngIf="template.requireReps">
              <mat-icon style="color: green">done</mat-icon>
            </div>
            <div *ngIf="!template.requireReps">
              <mat-icon style="color: red">close</mat-icon>
            </div>
          </span>
          <span fxLayout="row">{{'views.templates.requireLength' | translate }}
            <div fxFlex></div>
            <div *ngIf="template.requireLength">
              <mat-icon style="color: green">done</mat-icon>
            </div>
            <div *ngIf="!template.requireLength">
              <mat-icon style="color: red">close</mat-icon>
            </div>
          </span>
          <span fxLayout="row">{{'views.templates.requireIntensity' | translate }}
            <div fxFlex></div>
            <div *ngIf="template.requireIntensity">
              <mat-icon style="color: green">done</mat-icon>
            </div>
            <div *ngIf="!template.requireIntensity">
              <mat-icon style="color: red">close</mat-icon>
            </div>
          </span>
          <span fxLayout="row">{{'views.templates.requireWeight' | translate }}
            <div fxFlex></div>
            <div *ngIf="template.requireWeight">
              <mat-icon style="color: green">done</mat-icon>
            </div>
            <div *ngIf="!template.requireWeight">
              <mat-icon style="color: red">close</mat-icon>
            </div>
          </span>
          <span style="padding-bottom: 10px">{{'views.templates.muscleGroupsCount' | translate }} :
            <div fxFlex></div>
            {{ orZero(template.muscleGroups?.length) }}</span>
          <span style="padding-bottom: 10px">{{'views.templates.equipmentsCount' | translate }} :
            <div fxFlex></div>
            {{ orZero(template.equipments?.length) }}</span>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxFlex></div>
        <button mat-icon-button (click)="deleteTemplate(template.id)" color="primary">
          <mat-icon>delete</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
