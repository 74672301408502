import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/app/env/environment';
import { UserDocument } from './user.model';

@Component({
  selector: 'ga-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass']
})
export class UsersComponent {

  private readonly BASE_URL = environment.serverUrl;
  userDataSource = new MatTableDataSource<UserDocument>();

  isProd = environment.production;

  id: string | null = null;
  email: string | null = null;

  createdBefore: number | null = null;
  createdAfter: number | null = null;

  pageNumber = 0;
  pageSize = 10;

  userCount = 0;

  testUserMail: string = "";
  testUserPassword: string = "Qwerty123";
  testUserAdmin: boolean = false;

  loadingUsers: boolean = false;
  isCreatingTestUser: boolean = false;


  constructor(private httpClient: HttpClient) {
    this.generateRandomTestUserName();
    this.httpClient.get<number>(`${this.BASE_URL}/admin/user/count`)
      .subscribe(count => this.userCount = count);
    this.performSearch();
  }

  performSearch() {
    this.loadingUsers = true;
    this.httpClient.post<UserDocument[]>(
      `${this.BASE_URL}/admin/user/search?number=${this.pageNumber}&size=${this.pageSize}`,
      {
        "id": this.id,
        "email": this.email,
        "createdAfter": this.createdAfter,
        "createdBefore": this.createdBefore
      }
    ).subscribe({
      next: (response) => {
        this.userDataSource.data = response;
        this.loadingUsers = false;
      }
    })
  }

  createTestUser() {
    if (!this.isTestUserValid()) {
      return
    }

    this.isCreatingTestUser = true;
    this.httpClient.post(
      `${this.BASE_URL}/admin/user/test`,
      {
        "email": this.testUserMail,
        "password": this.testUserPassword,
        "grantAdmin": this.testUserAdmin
      }
    ).subscribe({
      next: () => {
        this.performSearch();
        this.generateRandomTestUserName();
        this.isCreatingTestUser = false;
      },
      error: () => this.isCreatingTestUser = false
    })
  }

  isTestUserValid(): boolean {
    return !!this.testUserMail && !!this.testUserPassword;
  }

  isAdmin(userDoc: UserDocument): boolean {
    return userDoc.roles && userDoc.roles.indexOf('ROLE_ADMIN') > -1;
  }

  onCreatedAfterChanged(event: MatDatepickerInputEvent<string>) {
    if (event.value) {
      this.createdAfter = Date.parse(event.value) / 1000;
    } else {
      this.createdAfter = null;
    }
  }

  onCreatedBeforeChanged(event: MatDatepickerInputEvent<string>) {
    if (event.value) {
      this.createdBefore = Date.parse(event.value) / 1000;
    } else {
      this.createdBefore = null;
    }
  }

  onPageChange(event: PageEvent) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
    this.performSearch();
  }

  private generateRandomTestUserName() {
    var random = Math.floor(Math.random() * 1000000)
    this.testUserMail = `test-${random}@gymapka`;
  }
}
